/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { ShoppingCart, ShoppingCartOutlined } from '@material-ui/icons';
import CartContent from '../Cart/CartContent';
import './style.scss';
import currencyFormat from '../../helpers/currencyFormat';
import cartService from '../../services/cart';
import Button from '../Button';
import { history } from '../../helpers/history';
import Timer from '../Timer';
import { CartText } from '../Cart/tooltipText';

export default function MobileCart({ cartProps }) {
  const refSheet = useRef();
  const [loadingCart, setLoadingCart] = useState(false);
  const { cartData } = cartProps;
  function cartTotals() {
    let total = 0;
    cartData.items?.map((item) => (total = parseFloat(total) + (parseFloat(item.price) * parseInt(item.qty, 10))));
    return currencyFormat(total);
  }

  function numItems() {
    let cartItems = 0;
    cartData.items?.map((item) => (cartItems = parseInt(cartItems, 10) + parseInt(item.qty, 10)));
    return cartItems;
  }

  function handleCartButton() {
    cartProps.appActions.handleCart();
    if (Object.entries(cartProps.userData).length > 0) {
      history.push('/checkout');
    } else {
      window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/login?redirect_to=${window.location.origin}/checkout&action=auth`;
    }
  }

  return (
    <>
      {window.innerWidth < 1024 && (
        <BottomSheet
          ref={refSheet}
          open
          blocking={false}
          scrollLocking={false}
          id="cart-mobile"
          header={(
            <section
              id="cart-mobile-header"
              onClick={() => {
                refSheet.current.snapTo(({
                  headerHeight, snapPoints, lastSnap,
                }) => (lastSnap > 200 ? headerHeight : Math.max(...snapPoints)));
              }}
            >
              <div className="header">
                <div className="item-count">
                  <ShoppingCartOutlined style={{ fontSize: 20, color: '#209869' }} />
                  <small className="timer-with-title">
                    {`${numItems()} ${numItems() > 1 ? 'itens' : 'item'}`}
                  </small>
                </div>
                <span className="cart-total">
                  <span className="total">{cartTotals()}</span>
                </span>
                <div className="timer">
                  <Timer
                    key="cart-timer"
                    tooltip={CartText}
                    tooltipAction="click"
                    tooltipPosition="bottomEnd"
                    onlyText
                    deadline={cartData.expiry_at}
                    cart={cartData}
                  />
                </div>
              </div>
            </section>
          )}
          snapPoints={({ maxHeight }) => [cartData.items?.length > 0 && 95, maxHeight * 0.95]}
          footer={(
            <Button type="button" disabled={loadingCart || cartData.items?.length === 0} width="100%" color="green" label="Fechar compra" action={handleCartButton} />
          )}
        >
          <CartContent setLoadingCart={setLoadingCart} loadingCart={loadingCart} cartProps={{ ...cartProps, cartData: cartData.items }} isMobile />
        </BottomSheet>
      )}
    </>
  );
}
