/* eslint-disable no-unused-expressions */
import cartService from '../../services/cart';
import { types } from './types';

export function addToCart(response) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CLEAR_CART_CREDITS });
      dispatch({ type: types.ADD_TO_CART, items: response?.data?.content });
      dispatch({ type: types.UPDATE_EXPIRY, expiry_at: response?.data?.expiry_seconds_at });

	    response?.data?.cartKey && localStorage.setItem('cartKey', response?.data?.cartKey);
    } catch (error) {
      console.log(error);
    }
  };
}
export function addToCartCredits(response, credits) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CLEAR_CART });
      dispatch({ type: types.ADD_CREDITS, credits });
	    response?.data?.cartKey && localStorage.setItem('cartKey', response.data.cartKey);
    } catch (error) {
      console.log(error);
    }
  };
}

export function removeToCart(item) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.REMOVE_TO_CART, item });
    } catch (error) {
      console.log(error);
    }
  };
}

export function expireCart() {
  return async (dispatch) => {
    try {
      dispatch({ type: types.EXPIRE_CART_FEEDBACK, value: true });
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateCart(currentItems = null) {
  return async (dispatch) => {
    try {
      if (localStorage.getItem('cartKey')) {
        if (!currentItems) {
          await cartService.getCart(localStorage.getItem('cartKey')).then((response) => {
            if (response.code === 904 || response.code === 0) { // CARRINHO EXPIRADO OU INEXISTENTE
              localStorage.removeItem('cartKey');
              updateCart([]);
            }
            if (response.data?.content[0].options?.wallet_topup) return null;
            dispatch({ type: types.UPDATE_CART, items: response.data?.content });
            dispatch({ type: types.UPDATE_EXPIRY, expiry_at: response.data?.expiry_seconds_at });
          });
          return;
        }
      }
      dispatch({ type: types.UPDATE_CART, items: currentItems });

      if (currentItems?.length === 0) {
        dispatch({ type: types.UPDATE_EXPIRY, expiry_at: null });
      }
    } catch (error) {
      console.log(error);
    }
  };
}
export function clearAllCarts() {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CLEAR_CART });
      dispatch({ type: types.CLEAR_CART_CREDITS });
      dispatch({ type: types.UPDATE_EXPIRY, expiry_at: null });
      localStorage.removeItem('cartKey');
      return true;
    } catch (error) {
      console.log(error);
    }
  };
}

export function destroyCart() {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CLEAR_CART });
      dispatch({ type: types.CLEAR_CART_CREDITS });
      dispatch({ type: types.UPDATE_EXPIRY, expiry_at: null });
      localStorage.removeItem('cartKey');
      return true;
    } catch (error) {
      console.log(error);
    }
  };
}
