/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/react-in-jsx-scope */
import { AccessTime } from '@material-ui/icons';
import { useEffect } from 'react';
import { Tooltip, Whisper } from 'rsuite';
import './style.scss';
import { useCountdown } from './timer';

export default function Timer({
  onlyText = true, cart = null, totalTime = 0, tooltipPosition = 'auto', refreshReduxCartExpiration = false, tooltipAction = 'hover', tooltip = '', onEnd = null, withLabel = false, deadline = null,
}) {
  if ((cart && (!cart?.expiry_at || (!cart?.items?.length > 0 && !cart?.count > 0))) || !deadline) return null;

  const {
    finished, currentInSeconds, minutes, seconds,
  } = useCountdown(deadline, cart?.length > 0, refreshReduxCartExpiration);

  useEffect(() => {
    onEnd && finished && onEnd();
  }, [finished]);

  if (currentInSeconds <= 0) return null;

  const TooltipText = (
    <Tooltip>
      {tooltip}
    </Tooltip>
  );

  const labelTimer = () => {
    if (!currentInSeconds) return <div />;

    return (
      <div
        className="text-countdown"
        style={{
          width: 90,
          background: currentInSeconds >= 300 ? '#2098694d' : '#f161654d',
          color: currentInSeconds >= 300 ? '#209869' : '#F16165',
        }}
      >
        <AccessTime style={{ fontSize: 20 }} />
        {`${minutes}:${seconds}`}
      </div>
    );
  };

  return (
    <>
      {!onlyText ? (
        <div id="countdown">
          <div className="container">
            <svg viewBox="0 0 232 232" style={{ '--percentage': (currentInSeconds / (totalTime || deadline)) * 100 }}>
              <circle cx="50%" cy="50%" r="98.5" opacity="0.1" stroke="#a7a5a5" />
              <circle cx="50%" cy="50%" r="98.5" />
              <text style={{ transform: 'rotate(90deg)' }} className="label" x="50%" y="-50%" dominantBaseline="middle" textAnchor="middle">{`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</text>
            </svg>
          </div>
        </div>
      ) : (
        <>
          {tooltip ? (
            <Whisper placement={tooltipPosition} trigger={tooltipAction} speaker={TooltipText}>
              {labelTimer()}
            </Whisper>
          ) : labelTimer()}
        </>
      )}
    </>
  );
}
