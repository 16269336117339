/* eslint-disable max-len,react/no-unescaped-entities */
import React from 'react';
import { renderToString } from 'react-dom/server';

export function UseTermsHTML() {
  const html = (
    <section>
      <h1>Termo condições de uso</h1>
      <article>
        <h1>1. DO OBJETO E DA ACEITAÇÃO</h1>
        <p>
          O presente documento tem por objetivo reger os serviços de intermediação prestados pela Unindo Sonhos (@unindosonhoss) – P C de S LEITE – LTDA., inscrição no CNPJ sob o nº 16.742.811/0001-06, com sede na Av. Nossa Senhora da Consolata, 645 c – Centro – Boa Vista/Roraima – CEP: 69301 - 011, com Contrato Social arquivado na Junta Comercial do Estado de Roraima sob o NIRE 14800055898, denominada INTERMEDIADORA, àqueles que acessarem o Portal http://www.unindosonhos.com.br para os fins aqui descritos, os quais são denominados simplesmente USUÁRIOS, cujo acesso se dará por meio da rede mundial de computadores/internet/aplicativo.
        </p>
        <p>O USUÁRIO somente poderá se utilizar dos serviços disponibilizados a eles pelo portal, após tomar  ciência integral e expressamente concordar com os presentes termos. </p>
        <p>
          1.3 O objeto do presente “Termos e Condições de Uso” é a intermediação realizada pela INTERMEDIADORA entre o USUÁRIO e CASAS LOTÉRICAS credenciadas pela CAIXA ECONÔMICA FEDERAL, para a realização, em nome do
          USUÁRIO, de apostas em jogos lotéricos oficiais, por meio de bolões, nos termos do art. 653 do Código Civil, servindo este instrumento como formalização da manifestação da vontade de contratar, realizada por meio da ciência e concordância de todos os termos constantes do Instagram @unindosonhoss e do respectivo pagamento realizado.
        </p>
      </article>

      <article>
        <h1>
          2. DA INTERMEDIAÇÃO
        </h1>
        <p>
          2.1 Na atuação disponibilizada pelo Portal http://www.unindosonhos.com.br, o USUÁRIO, mediante contrato de mandato, outorga poderes à
          INTERMEDIADORA para que esta, em seu nome, efetive junto a Casa Lotérica oficial as apostas que indicar, além de deixar sob sua responsabilidade a conferência, envio de resultados, recebimento e distribuição de eventuais premiações.
        </p>
        <p>
          2.2. Pela intermediação prevista na Cláusula 2.1 dos presentes “Termos e Condições de Uso”, o USUÁRIO pagará à CONTRATADA, valor determinado, constante do Portal http://www.unindosonhos.com.br ou respectivo aplicativo, já incluída a respectiva retribuição nos termos do art. 676 do Código Civil, por se tratar de mandato oneroso.
        </p>
        <p>
          2.3. O pagamento deverá ser realizado por meio de PIX ou saldo contido na CARTEIRA DO CLIENTE, no prazo informado pela plataforma durante a compra.
          Eventuais dúvidas sobre os presentes termos deverão ser sanadas por meio do Portal http://www.unindosonhos.com.br, no canal de atendimento ao cliente ou através do canal de SUPORTE do Telegram ou WhatsApp, os quais são constantemente divulgados no Instagram oficial da INTERMEDIADORA.
        </p>
        <p>
          2.4. A INTERMEDIADORA receberá, em nome do USUÁRIO, eventuais valores oriundos de prêmios junto à CAIXA ECONÔMICA FEDERAL, com consequente crédito na CARTEIRA DO CLIENTE do USUÁRIO, podendo o mesmo transferir o valor para conta bancária de sua titularidade, após solicitação de transferência na própria plataforma, conforme disposto no art.
          653 e seguintes do Código Civil.
        </p>
      </article>

      <article>
        <h1>
          3. CONDIÇÕES NECESSÁRIAS PARA A UTILIZAÇÃO DA INTERMEDIAÇÃO PELO USUÁRIO
        </h1>

        <p>3.1. Para solicitar os serviços, o USUÁRIO deverá ser pessoa física, maior de idade (acima de 18 anos) e juridicamente capaz. </p>
        <p>3.2. Efetuar cadastro prévio no Portal ou no Aplicativo. </p>
        <p>3.3. Realizar o pagamento do valor constante da cláusula 2.2, objeto do presente contrato, observadas as condições previstas na Cláusula 2.3.</p>
        <p>3.4. Ser, na data da contratação, maior de 18 anos de idade e plenamente capaz. </p>
        <p>3.5. Garantir a veracidade das informações e dados cadastrados no Portal ou Aplicativo, responsabilizando-se integralmente por eventuais danos decorrentes dessa omissão, comprometendo-se a mantê-los atualizados. </p>
        <p>
          3.6. Permitir o acesso da INTERMEDIADORA aos seus dados pessoais para os fins propostos nestes “Termos e Condições de Uso”.
        </p>
        <p>3.7. A UNINDO SONHOS não assume qualquer obrigação de policiar ou fiscalizar as informações fornecidas pelo USUÁRIO, mas poderá, ao seu exclusivo critério, excluir as informações que lhe pareçam inverídicas ou ofensivas. </p>

      </article>

      <article>
        <h1>
          4. SENHAS DE ACESSO AO PORTAL E AO APLICATIVO
        </h1>

        <p>4.1. A senha de acesso ao PORTAL e ao APLICATIVO é de caráter pessoal e intransferível, sendo sua correta e cuidadosa utilização de inteira responsabilidade do USUÁRIO.</p>
        <p>4.2. É terminantemente proibido o repasse da senha de acesso para terceiros.</p>
        <p>4.3. A senha de acesso é criptografada e deverá conter caracteres/números conforme solicitação no momento da realização do cadastro.</p>
        <p>4.4. A INTERMEDIADORA não possui acesso à senha pessoal do USUÁRIO.</p>
        <p>4.5. A senha poderá ser alterada pelo USUÁRIO a qualquer momento, através do Portal ou pelo Aplicativo. E em caso de qualquer eventualidade, USUÁRIO deverá informar ao SUPORTE, para receber o devido auxílio.</p>
        <p>4.6. Em caso de perda ou extravio da senha de acesso, o USUÁRIO deverá comunicar imediatamente a INTERMEDIADORA para que sejam tomadas as devidas providências. Eventuais danos ocorridos em razão da perda ou extravio da senha serão suportados integralmente pelo USUÁRIO.</p>
        <p>4.7. É expressamente proibida a utilização do Portal ou Aplicativo por terceiro, responsabilizando-se o USUÁRIO por eventual cessão de sua senha particular.</p>

      </article>

      <article>
        <h1>
          5. DAS APOSTAS
        </h1>

        <p>5.1. A intermediação será realizada apenas em apostas na modalidade bolão. </p>
        <p>5.2. Em caso de inviabilidade do processamento das apostas em tempo hábil para o concurso escolhido pelo USUÁRIO, em razão de caso fortuito ou força maior, como por exemplo problemas técnicos e/ou operacionais, intempéries climáticas, culpa exclusiva de terceiro, entre outras, a INTERMEDIADORA se exime de quaisquer responsabilidades, cancelando automaticamente as apostas realizadas com o estorno integral do valor pago para a carteira do cliente, em até 3 (TRÊS ) dias úteis. </p>
        <p>5.3. Realizadas as apostas, a INTERMEDIADORA se responsabilizará pela guarda e custódia dos respectivos bilhetes. </p>
        <p>
          5.4. A INTERMEDIADORA realizará a apuração e fará o rateio entre os apostadores automaticamente, utilizando o resultado oficial do sorteio correspondente. Em caso de premiação, todos serão comunicados via e-mail, porém o USUÁRIO também poderá acessar o resultado de suas apostas em até 2 (DOIS) dias úteis após o sorteio, através do Portal http://www.unindosonhos.com.br, na opção "MINHAS APOSTAS" no menu da sua conta.
        </p>
        <p>5.5. O rateio do prêmio será creditado na “CARTEIRA DO CLIENTE”, em até 3 (TRÊS) dias úteis após o sorteio. 5.6. Em relação à premiação, o ganhador terá duas opções: </p>
        <p>a.	SALDO</p>
        <p>
          O USUÁRIO poderá manter o valor do prêmio em sua CARTEIRA DO
          CLIENTE, para utilizá-lo em novas apostas, desde que o valor do saldo seja igual ou maior ao valor da cota desejada.
        </p>
        <p>b.	TRANSFERÊNCIA </p>
        <p>O USUÁRIO poderá solicitar a transferência do valor presente em sua CARTEIRA DO CLIENTE, para uma conta bancária de sua titularidade, desde que o valor do saldo seja igual ou superior a R$20,00 ( VINTE REAIS). </p>
        <p>b.1.	O USUÁRIO receberá o valor de seu resgate em até 5 ( CINCO) DIAS ÚTEIS, a partir da data de sua solicitação, desde que todos os dados bancários tenham sido digitados corretamente no sistema, para que não haja nenhuma divergência no sistema de pagamento. </p>
        <p>b.2.	Não será cobrado qualquer percentual a título de taxa sobre o valor da premiação, apenas uma TAXA ADMINISTRATIVA de R$5,00 (CINCO REAIS), que será descontada no ato da transferência do saldo da CARTEIRA DO CLIENTE, solicitada pelo USUÁRIO.  </p>
        <p>5.7. As apostas só poderão ser realizadas até às 18:00h (horário de Brasília-DF) do dia do sorteio, sendo que apostas feitas após esse horário não concorrerão e, caso tenha sido feito algum pagamento pelo USUÁRIO, o(s) valor(es) será(ão) reembolsado(s), mediante requerimento, dentro de 3 (três) dias úteis após a confirmação do pagamento pela Unindo Sonhos.</p>
        <p>5.8.	O USUÁRIO deverá, antes de concluir a aposta, certificar-se de que todas as informações (bolão, aposta, números, etc.) estão corretas, uma vez que o procedimento é irreversível, após ter sido concluído, não sendo possível retificação posterior e nem o estorno de eventuais valores pagos.   </p>
        <p>5.9.	Informação importante ao USUÁRIO: A Unindo Sonhos apenas faz a intermediação das apostas, as quais são disponibilizadas pelas Casas Lotéricas credenciadas e/ou pela CAIXA ECONÔMICA FEDERAL e, após o sorteio ter sido realizado ou havendo a divulgação dos números sorteados (diariamente ou em dias específicos), as Lotéricas e a Caixa não devolvem, em hipótese alguma, os valores que pagamos pelos bolões efetuados, de modo que a Unido Sonhos também não faz a devolução dos valores que o USUÁRIO pagou. Por isso, reforça-se o disposto nos itens 5.7 e 5.8 acima, quanto aos cuidados a serem observados pelo USUÁRIO antes de finalizar sua aposta.   </p>

      </article>

      <article>
        <h1>
          6. DO ACESSO À INTERMEDIAÇÃO PELO PORTAL / APLICATIVO
        </h1>

        <p>6.1. A INTERMEDIADORA concede acesso ao Portal e ao Aplicativo por meio de licença revogável, onerosa e intransferível de uso.</p>
        <p>6.2. A INTERMEDIADORA poderá, a qualquer tempo e seu livre arbítrio, sem necessidade de aviso prévio:</p>
        <p>6.2.1 Suspender ou excluir o acesso do USUÁRIO do Portal e/ou Aplicativo caso este venha a adotar conduta ilícita, que afete a moral, a ética e os bons costumes, como, por exemplo, ofender outros usuários ou operadores, solicitar cancelamento de apostas efetuadas alegando motivo inverídico ou fraudulento, entre outros.   </p>
        <p>6.2.2 Excluir informações constantes do cadastro do USUÁRIO que não estejam de acordo com os presentes Termos, ou que ofendam a moral, os bons costumes, ou ainda, que tenham denotação homofóbica, racial, xenofóbica ou qualquer outra foram de discriminação.</p>
        <p>6.2.3 Suspender, encerrar ou excluir o cadastro do USUÁRIO se tiver fundadas suspeitas de que ele esteja utilizando-se das apostas para fins que transgridam a lei, em especial a lei que dispõe sobre os crimes contra o sistema financeiro nacional (Lei nº. 7.492./1986), e do combate à lavagem de dinheiro (Lei nº. 9.613/1998). Neste caso a INTERMEDIADORA poderá agir de modo a informar as autoridades competentes.   </p>
        <p>6.3. O USUÁRIO não poderá:</p>
        <p>6.3.1 Utilizar  o Portal ou Aplicativo para divulgar informações ou para realizar manifestações que atentem contra qualquer disposição legal.   </p>
        <p>6.3.2 Copiar, vender, ceder, locar, reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente, o Aplicativo, ainda que em partes, ou informações sobre seu código fonte.   </p>
        <p>6.3.3 Empregar artifícios, como softwares/malware, com o intuito de burla ou causar prejuízo à INTERMEDIADORA, sob pena de responsabilização civil e criminal.   </p>
        <p>6.3.4 Reproduzir, adaptar, modificar ou empregar, no todo ou em parte, para qualquer fim, o Portal e/ou Aplicativo, sem prévia autorização da INTERMEDIADORA.   </p>
        <p>6.3.5 Publicar ou fazer publicar divulgações com intuito de denegrir a imagem da INTERMEDIADORA ou dos demais USUÁRIOS.   </p>
        <p>6.3.6 Simular a aquisição de crédito em sua conta junto à INTERMEDIADORA para posteriormente solicitar o estorno e assim antecipar valores por meio de seu cartão de crédito. Restando evidenciada essa prática, não haverá restituição em dinheiro e eventuais estornos de valores somente poderão ser realizados por intermédio da respectiva operadora do cartão de crédito do USUÁRIO.   </p>
        <p>6.3.7 Simular a aquisição de créditos através de qualquer transação financeira, como PIX ou boleto bancário, com o objetivo de imediatamente requerer o resgate do montante correspondente, é considerado uma violação dos termos de serviço. Essa conduta pode acarretar medidas legais, como bloqueio de acesso à conta no site, anulação de transações e potencial responsabilização civil e criminal, conforme a situação. </p>

      </article>

      <article>
        <h1>
          7. LIMITAÇÃO DE RESPONSABILIDADE E INDENIZAÇÃO
        </h1>
        <p>7.1. A INTERMEDIADORA não se responsabiliza, em nenhuma hipótese:</p>
        <p>7.1.1 Por eventuais prejuízos sofridos pelo USUÁRIO em razão de falhas no sistema de informática, de telefonia ou em servidores que o impeçam de usufruir, de qualquer forma, da intermediação ofertada pela INTERMEDIADORA.</p>
        <p>7.1.2 Por eventuais prejuízos oriundos de fraudes praticadas por terceiros, devendo o USUÁRIO manter, às suas expensas e sob sua responsabilidade, softwares e medidas de proteção contra vírus, malwares, Worms etc.</p>
        <p>7.1.3 Por situações decorrentes de caso fortuito, força maior ou culpa exclusiva de terceiro.   </p>
        <p>
          7.2. A INTERMEDIADORA não garante premiações e  que as funções do Aplicativo ou Portal atendam todas as necessidades do USUÁRIO, bem como não garante que a operação do Aplicativo ou Portal seja ininterrupta ou livre e erros, situação com a qual fica o USUÁRIO ciente. Em caso de indisponibilidade ou erro no
          Aplicativo ou Portal, a INTERMEDIADORA garante que, na ocorrência de eventualidades dessa estirpe, lançará mão de todos os meios disponíveis para que as funções sejam restabelecidas o mais rápido possível.
        </p>
        <p>7.3. O USUÁRIO deverá efetuar suas apostas em tempo hábil para que, em caso de indisponibilidade do sistema, possa realizá-las por outro meio.    </p>
        <p>7.4. A INTERMEDIADORA não se responsabiliza em caso de não realização de apostas por indisponibilidade ou erro do sistema, bem como em razão da inexistência de cota. Neste caso, os valores eventualmente pagos à INTERMEDIADORA serão estornados/restituídos ao USUÀRIO.   </p>
        <p>7.5. Na hipótese de compra simultânea de cotas, será considerado adquirente aquele USUÁRIO que primeiro efetuar o pagamento à INTERMEDIADORA, razão pela qual os USUÁRIOS que não tiverem a compra finalizada receberão em sua carteira os créditos eventualmente estornados pela INTERMEDIADORA.   </p>
        <p>7.6. A INTERMEDIADORA, não será responsável por danos pessoais, materiais ou morais, ou qualquer prejuízo direto ou indireto, lucros cessantes, danos emergentes, perda de uma chance que eventualmente o USUÁRIO suporte em razão da intermediação objeto destes “Termos e Condições de Uso”.   </p>

      </article>

      <article>
        <h1>
          8. DA VIGÊNCIA
        </h1>

        <p>8.1. Estes “Termos e Condições de Uso” terão vigência por prazo indeterminado, a partir do aceite do Usuário, quando terá força de contrato entre as partes.   </p>
        <p>8.2. Os presentes “Termos e Condições de Uso” poderão ser modificados ou rescindidos unilateralmente pela INTERMEDIADORA, a qualquer momento, sem qualquer ônus, mediante simples comunicação ao USUÁRIO, por meio do próprio Aplicativo, Portal, e-mail, ou outro meio idôneo.   </p>

      </article>

      <article>
        <h1>
          9. DAS REGRAS GERAIS, DA LEGISLAÇÃO APLICÁVEL E DO FORO
        </h1>

        <p>9.1. Na utilização do Aplicativo e do Portal, O USUÁRIO se compromete a agir com responsabilidade, lisura e a respeitar a lei, a moral e os bons costumes.   </p>
        <p>9.2. As disposições destes “Termos e Condições de Uso”, bem como quaisquer informações ou orientações divulgadas por meio do Portal ou do Aplicativo constituem-se em normas que regem a relação entre USUÁRIO E INTERMEDIADORA.   </p>
        <p>9.3. A relação contratual entre USUÁRIO e INTERMEDIADORA é regida pelas disposições do Código Civil relativas ao Contrato de Mandato, artigos 653 e seguintes da Lei nº. 10.406/2002.   </p>
        <p>9.4. . Em razão das regras nacionais e internacionais relativas à prevenção de crimes de corrupção, lavagem de dinheiro e ao financiamento de atividades criminosas, a INTERMEDIADORA poderá compartilhar quaisquer informações que detenha em razão desta relação contratual, com quaisquer órgãos governamentais.   </p>
        <p>9.5. Em nenhuma hipótese o USUÁRIO terá acesso ao código fonte do Portal ou Aplicativo, por se tratar de propriedade intelectual da INTERMEDIADORA.   </p>
        <p>9.6. Todo conteúdo constante do Portal ou do Aplicativo, incluindo, mas não se limitando, os textos, gráficos, imagens, logos, ícones, fotografias, material editorial, notificações, são de propriedade exclusiva da INTERMEDIADORA, sendo protegidos pela lei de Propriedade Intelectual (Lei n. 9.609/98)   </p>
        <p>
          9.7. O USUÁRIO é responsável pelas informações e dados bancários informados à INTERMEDIADORA, não se podendo a ela atribuir eventuais prejuízos pelo
          não recebimento de prêmios em razão de incorreções nos dados bancários informados.
        </p>
        <p>9.8. Para fins de informação, a INTERMEDIADORA esclarece e salienta que não possui qualquer vínculo com a CAIXA ECONÔMICA FEDERAL nem com Lotéricas Credenciadas, atuando apenas na intermediação entre o USUÁRIO e aquelas na efetivação das apostas.   </p>
        <p>9.9. Em caso de contemplação, o prêmio, no importe mínimo de R$ 20,00 (vinte reais), será transferido ao USUÁRIO, em até 5 (cinco) dia úteis, contados a partir do sorteio, em parcela única, por meio de transferência bancária para conta e banco por ele indicada, na proporção da (s) Cota(s) por ele adquirida(s), sendo o comprovante de transferência prova irrefutável da quitação, não tendo o USUÁRIO mais nada a receber ou reclamar.   </p>
        <p>9.10. O valor do prêmio pode variar para mais ou para menos a depender exclusivamente das regras do respectivo concurso conforme normas estipuladas pela CAIXA ECONÔMICA FEDERAL, a qual compete também a divulgação dos valores em cada caso, bem como não respondendo a INTERMEDIADORA, em nenhuma hipótese, por essa variação de valor.   </p>
        <p>9.11. A INTERMEDIADORA não é uma instituição financeira, em razão disso não serão pagos quaisquer acréscimos ao valor que eventualmente o USUÁRIO alegar fazer jus.   </p>
        <p>9.12. Caso necessário, poderão ser solicitados documentos comprobatórios para atestar a legitimidade da transação de transferência dos créditos/prêmios para a conta do USUÁRIO.   </p>
        <p>9.13. Caberá ao USUÁRIO recolher os tributos relativos a eventual premiação. Neste caso, ao repassar o prêmio correspondente à cota do USUÁRIO, este AUTORIZA a retenção de valores dessa natureza.   </p>
        <p>9.14. A CONTRATADA se reserva o direito de suspender, encerrar ou excluir a participação do USUÁRIO em apostas, se tiver fundadas suspeitas de que o USUÁRIO esteja se utilizando das apostas para fins que transgridam a lei, em especial a lei que dispõe sobre os crimes contra o sistema financeiro nacional (Lei nº. 7.492/1986), e do combate à lavagem de dinheiro (Lei nº. 9.613/1998). Neste caso a CONTRATADA poderá agir de modo a informar as autoridades competentes.   </p>
        <p>9.15. O USUÁRIO declara expressamente que leu integralmente todas as cláusulas destes “Termos e Condições de Uso”, bem como todas as informações constantes no Portal Unindo Sonhos, com as quais concorda sem quaisquer reservas.   </p>
        <p>9.16. As Partes elegem o Foro da Comarca de Boa Vista/RR para dirimir judicialmente eventuais controvérsias que surjam em decorrência do uso do Portal ou Aplicativo Unindo Sonhos.   </p>

      </article>

      <p>Boa Vista, 08 de maio de 2024.</p>

      <h1>Termo de Consentimento para Tratamento de Dados</h1>

      <article>
        <p>Este documento visa registrar a manifestação livre, informada e inequívoca pela qual o Titular concorda com o tratamento de seus dados pessoais para finalidade específica, em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD).   </p>
        <p>Ao manifestar sua aceitação para com o presente termo, o Titular consente e concorda que a Unindo Sonhos (@Unindosonhoss) – PC de S LEITE – ME, inscrição no CNPJ sob o nº 16.742.811/0001-06, com sede na Av. Nossa Senhora da Consolata, 645 c – Centro – Boa Vista/Roraima – CEP: 69301 - 011, doravante denominada Controladora, tome decisões referentes ao tratamento de seus dados pessoais, dados referentes as empresas em que atuem os usuários ou dados necessários ao usufruto de serviços ofertados, bem como realize o tratamento de tais dados, envolvendo operações como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.   </p>
      </article>

      <article>
        <h1>Dados Pessoais</h1>
        <p>A Controladora fica autorizada a tomar decisões referentes ao tratamento e a realizar o tratamento dos seguintes dados pessoais do Titular:</p>
        <ul>
          <li>Nome completo;</li>
          <li>Data de nascimento;</li>
          <li>Número e imagem da Carteira de Identidade (RG);</li>
          <li>Número e imagem do Cadastro de Pessoas Físicas (CPF);</li>
          <li>Número e imagem da Carteira Nacional de Habilitação (CNH);</li>
          <li>Número do Cadastro Nacional de Pessoas Jurídicas (CNPJ);</li>
          <li>Fotografia 3x4;</li>
          <li>Estado civil;</li>
          <li>Filiação;</li>
          <li>Nível de instrução ou escolaridade;</li>
          <li>Endereço completo;</li>
          <li>Números de telefone, WhatsApp, endereços de e-mail e identificações em redes sociais;</li>
          <li>Banco, agência e número de contas bancárias;</li>
          <li>Bandeira, número, validade e código de cartões de crédito;</li>
          <li>Nome de usuário e senha específicos para uso dos serviços do Controlador;</li>
          <li>Extratos de movimentações efetuadas pelo Titular em sua conta no Portal/Aplicativo UnindoSonhos.</li>
        </ul>
        <p>Além disso, a Controladora fica autorizada a tomar decisões referentes ao tratamento e a realizar o tratamento dos dados inseridos pelo Titular, com a finalidade de divulgação dos serviços, inclusive publicidade.</p>
      </article>

      <article>
        <h1>Finalidades do Tratamento dos Dados:</h1>
        <p>O tratamento dos dados pessoais listados neste termo tem as seguintes finalidades:</p>
        <ul>
          <li>
            Possibilitar que a Controladora identifique e entre em contato com o Titular para fins de relacionamento comercial.
          </li>
          <li>
            Possibilitar que a Controladora elabore contratos comerciais e emita cobranças contra o Titular.
          </li>
          <li>
            Possibilitar que a Controladora envie ou forneça ao Titular seus produtos e serviços, de forma remunerada ou gratuita.
          </li>
          <li>
            Possibilitar que a Controladora estruture, teste, promova e faça propaganda de produtos e serviços, personalizados ou não ao perfil do Titular.
          </li>
          <li>
            Possibilitar que a Controladora utilize tais dados em Pesquisas de Mercado;
          </li>
          <li>
            Possibilitar que a Controladora utilize tais dados na inscrição, divulgação, premiação dos interessados participantes de Eventos, Prêmios ou Concursos;
          </li>
          <li>
            Possibilitar que a Controladora utilize tais dados na elaboração de relatórios e emissão de produtos e serviços;
          </li>
          <li>
            Possibilitar que a Controladora utilize tais dados para emissão de Notas Fiscais e documentos financeiros correlatos;
          </li>
          <li>
            Possibilitar que a Controladora utilize tais dados para facilitar a prestação de serviços diversos além dos primariamente contratados, desde que o cliente também demonstre interesse em contratar novos serviços;
          </li>
          <li>
            Possibilitar que a Controladora utilize tais dados para manter banco de dados de profissionais do mercado para facilitar o contato em futuros convites para eventos;
          </li>
          <li>
            Possibilitar que a Controladora utilize tais dados para: (i) mitigar fraudes eletrônicas considerando a análise de perfil do Titular, histórico de transações realizadas e informações existentes em bancos de dados e aplicando o modelo estatístico de indicador de propensão à fraude pelas mesas de análise de risco, a fim de conferir o status sobre a suspeita de fraude eletrônica e evitar que sejam realizadas transações por terceiros em nome do Titular e/ou utilizando informações falsas; (ii) criar um cadastro de pontuação baseada no histórico de transações realizadas pelo Titular; e (iii) fins estatísticos sem a individualização dos referidos dados.
          </li>
          <li>
            Exercício do contraditório e ampla defesa.
          </li>
        </ul>
      </article>

      <article>
        <h1>Compartilhamento de Dados</h1>
        <p>
          A Controladora fica autorizada a compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados, caso seja necessário para as finalidades listadas neste termo, observados os princípios e as garantias estabelecidas pela Lei nº 13.709/18.
        </p>
      </article>

      <article>
        <h1>Segurança dos Dados</h1>
        <p>A Controladora responsabiliza-se pela manutenção de medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas ou qualquer forma de tratamento inadequado ou ilícito.   </p>
        <p>Em conformidade ao art. 48 da Lei nº 13.709/18, a Controladora comunicará ao Titular e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao Titular.   </p>
      </article>

      <article>
        <h1>Término do Tratamento dos Dados</h1>
        <p>A Controladora poderá manter e tratar os dados pessoais do Titular durante todo o período em que os mesmos forem pertinentes ao alcance das finalidades listadas neste termo. Dados pessoais anonimizados, sem possibilidade de associação ao indivíduo, poderão ser mantidos por período indefinido.   </p>
        <p>O Titular poderá solicitar via e-mail ou correspondência ao Controlador, a qualquer momento, que sejam eliminados os dados pessoais não anonimizados do Titular. O Titular fica ciente de que poderá ser inviável ao Controlador continuar o fornecimento de produtos ou serviços ao Titular a partir da eliminação dos dados pessoais.   </p>
        <p>Em especial o Titular de dados autoriza, nos termos do art. 7º VI da LGPD, que a Unindo Sonhos (@Unindosonhoss) conserve seus dados pelo prazo de 10 (dez) anos para fins de eventual exercício de direitos fundamentais, como o exercício de Contraditório e Ampla Defesa em eventual processo judicial e/ou administrativo.   </p>
      </article>

      <article>
        <h1>Direitos do Titular</h1>
        <p>O Titular tem direito a obter da Controladora, em relação aos dados por ele tratados, a qualquer momento e mediante requisição:</p>
        <ul>
          <li>confirmação da existência de tratamento;</li>
          <li>acesso aos dados;</li>
          <li>correção de dados incompletos, inexatos ou desatualizados;</li>
          <li>anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709/18;</li>
          <li>portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa e observados os segredos comercial e industrial, de acordo com a regulamentação do órgão controlador;</li>
          <li>portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</li>
          <li>eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709/18;</li>
          <li>informação das entidades públicas e privadas com as quais a Controladora realizou uso compartilhado de dados;</li>
          <li>informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
        </ul>
      </article>

      <article>
        <h1>Direito de Revogação do Consentimento </h1>
        <p>Este consentimento poderá ser revogado pelo Titular, a qualquer momento, mediante solicitação via e-mail ou correspondência à Controladora.</p>
      </article>
    </section>
  );

  return renderToString(html);
}
