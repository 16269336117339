import React from 'react';

export default function formatOrderStatus(status) {
  if (status === 'pending') {
    return (
      <div>
        <span className="info-badge-status" style={{ backgroundColor: '#D8C364' }} />
        <span className="info-value text-right">
          Pendente
        </span>
      </div>
    );
  }

  if (status === 'completed') {
    return (
      <div className="status-label self-start">
        <span className="info-badge-status" style={{ backgroundColor: '#209869' }} />
        <span className="info-value text-right">
          Concluído
        </span>
      </div>
    );
  }

  if (status === 'canceled') {
    return (
      <div>
        <span className="info-badge-status" style={{ backgroundColor: '#D86470' }} />
        <span className="info-value text-right">
          Cancelado
        </span>
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div>
        <span className="info-badge-status" style={{ backgroundColor: '#D86470' }} />
        <span className="info-value text-right">
          Falha
        </span>
      </div>
    );
  }

  return '';
}
