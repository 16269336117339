import React from 'react';

import { UseTermsHTML } from '../../data/use-terms';
import './style.scss';

class TermsAndCondition extends React.Component {
  render() {
    return (
      <main id="terms-and-conditions">
        <div className="container">

          <div className="row">
            <h1>Termos e Condições</h1>
            <p>
              Olá usuário, Por favor, leia o seguinte Acordo de Termos
              de Uso e Limitação de Responsabilidade antes de usar o
              unindosonhos.com.br. A aceitação desse termo é obrigatória
              para o uso dos serviços do Unindo Sonhos.
            </p>
          </div>

          <div className="row">
            <span dangerouslySetInnerHTML={{ __html: UseTermsHTML() }} />
          </div>

        </div>
      </main>
    );
  }
}
export default TermsAndCondition;
