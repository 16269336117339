/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable class-methods-use-this */
/* eslint-disable linebreak-style */
/* eslint-disable radix */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-return-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Drawer } from 'rsuite';

import { isMobile } from 'react-device-detect';
import * as appActions from '../../store/app/actions';
import * as cartActions from '../../store/cart/actions';
import { history } from '../../helpers/history';
import currencyFormat from '../../helpers/currencyFormat';
import cartService from '../../services/cart';
import { ReactComponent as IconLoteria } from '../../assets/images/loteria.svg';
import './style.scss';
import { CartContent } from './CartContent';
import Timer from '../Timer';
import { CartText } from './tooltipText';

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: ['', false],
      loadingCart: false,
    };

    this.cartOverlay = React.createRef;

    this.loading = this.loading.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.numItems = this.numItems.bind(this);
    this.cleanCart = this.cleanCart.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);

    const imagesPreload = [IconLoteria];
    imagesPreload.forEach((image) => {
      const newImage = new Image();
      newImage.src = image;
      window[image] = newImage;
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  componentDidUpdate() {
    !isMobile && this.props.appData.openCart ? document.body.classList.add('no-scroll-cart') : document.body.classList.remove('no-scroll-cart');
  }

  handleClick(event) {
    if (this.cartOverlay === event.target) { this.props.appActions.handleCart(); }
  }

  loading(id, status) {
    this.setState({ ...this.state, loading: [[...this.state.loading[0], ...id], status] });
  }

  cleanCart() {
    this.setState({ ...this.state, loadingCart: true });
    cartService.destroyCart(localStorage.getItem('cartKey')).then((response) => {
      this.props.cartActions.destroyCart();
      this.setState({ ...this.state, loadingCart: false });
    });
  }

  numItems() {
    let numItems = 0;
    const { cartData } = this.props;
    cartData?.length && cartData?.map((item) => (numItems = parseInt(numItems) + parseInt(item.qty)));
    return numItems;
  }

  render() {
    const { cartData } = this.props;
    const cartDataGroupedBySKU = cartData?.reduce((cartItem, item) => ({
      ...cartItem,
      [`${item?.options?.concourse}|${item?.options?.type_game}`]: [...(cartItem[`${item?.options?.concourse}|${item?.options?.type_game}`] || []), item],
    }), {});
    return (
      <Drawer
        id="cart"
        open={window.innerWidth < 1024 ? false : this.props.appData.openCart}
        placement="right"
        size="sm"
        onClose={() => this.props.appActions.handleCart()}
      >
        <Drawer.Header>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
          }}
          >
            <Drawer.Title>
              Carrinho
              <span style={{ fontWeight: 600, marginLeft: 4 }}>{`(${this.numItems()} ${this.numItems() > 1 ? 'itens' : 'item'})`}</span>
              <Timer
                key="cart-timer"
                tooltip={CartText}
                tooltipPosition="bottomStart"
                onlyText
                deadline={this.props.cart.expiry_at}
                cart={this.props.cart}
              />
            </Drawer.Title>
            <Drawer.Actions>
              <button type="button" disabled={(cartDataGroupedBySKU && Object.entries(cartDataGroupedBySKU).length === 0) || this.state.loadingCart || this.state.loading[1]} className="clean-cart-button" onClick={this.cleanCart}>Limpar</button>
            </Drawer.Actions>
          </div>
        </Drawer.Header>
        <CartContent cartProps={this.props} loadingCart={this.state.loadingCart} />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.accountReducer.userData,
  appData: state.appReducer,
  cartData: state.cartReducer.items,
  cart: state.cartReducer,
  cartCredits: state.cartReducer.credits,
});
const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  cartActions: bindActionCreators(cartActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
