/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { Panel, PanelGroup } from 'rsuite';
import React, { useEffect, useState } from 'react';
import { AccountBalanceWallet } from '@material-ui/icons';
import { BoletoIcon, CardIcon, PixIcon } from '../Icons';
import '../style.scss';
import { showPayForm } from './functions';

export default function PaymentOptions({
  setPaymentMethod,
  methods,
  checkoutData,
  setCheckoutData,
  updateCardList,
  setOpenCardList,
}) {
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [cardToDefault, setCardToDefault] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [showAllCards, setShowAllCards] = useState(false);
  const [sessionCard, setSessionCard] = useState(
    checkoutData.sessionDefaultCard,
  );

  useEffect(() => {
    setOpenCardList(showAllCards);
  }, [showAllCards]);

  const paymentOptions = [
    {
      type: 'wallet',
      icon: <AccountBalanceWallet />,
      title: 'Carteira',
      show: methods.includes('wallet') && checkoutData.founds
        >= checkoutData.cart.total_price,
    },
    {
      type: 'pix',
      icon: <PixIcon isSelected={selectedPayment === 'pix'} />,
      title: 'Pix',
      show: methods.includes('pix'),
    },
    {
      type: 'boleto',
      icon: <BoletoIcon isSelected={selectedPayment === 'boleto'} />,
      title: 'Boleto',
      show: methods.includes('boleto'),
    },
    {
      type: 'credit_card',
      icon: <CardIcon />,
      title: 'Cartão de crédito',
      show: methods.includes('credit_card') && checkoutData.cards?.length >= 1,
    },
  ];

  useEffect(() => {
    setShowAllCards(false);
	  setPaymentMethod(selectedPayment);
  }, [selectedPayment]);

  return (
    <div className="pay-options">
      <h1 className="label">Formas de pagamento</h1>
      <PanelGroup accordion defaultActiveKey={0} bordered={false}>
        {paymentOptions.map((payment) => {
          if (payment.show === false) return null;
          const isActive = selectedPayment === payment.type;
          return (
            <Panel
              header={(
                <button
                  onClick={() => setSelectedPayment(
                    selectedPayment && payment.type === selectedPayment
                      ? null
                      : payment.type,
                  )}
                  type="button"
                  className={`select-payment wallet ${isActive
                    ? 'active'
                    : ''}`}
                >
                  <div className={`radio ${isActive ? 'active' : ''}`}>
                    <span className="radio-button" />
                  </div>
                  {payment.icon}
                  {payment.title}
                </button>
              )}
              eventKey={payment.type}
              id={payment.type}
            >
              {showPayForm({
                updateCardList,

                sessionCard,
                setSessionCard,

                showAllCards,
                setShowAllCards,

                checkoutData,
                setCheckoutData,

                loadingCards,
                setLoadingCards,

                cardToDefault,
                setCardToDefault,

                method: payment.type,
              })}
            </Panel>
          );
        })}
      </PanelGroup>
    </div>
  );
}
