/* eslint-disable max-len,no-unused-expressions */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const getReturnValues = (countDown, finished) => {
  // calculate time left
  const currentInSeconds = parseInt(countDown, 10);

  const days = Math.floor(currentInSeconds / (3600 * 24)).toString().padStart(2, '0');
  const hours = Math.floor((currentInSeconds % (3600 * 24)) / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((currentInSeconds % 3600) / 60).toString().padStart(2, '0');
  const seconds = Math.floor(currentInSeconds % 60).toString().padStart(2, '0');

  return {
    finished,
    days,
    hours,
    minutes,
    seconds,
    currentInSeconds,
  };
};

const useCountdown = (expirationDate, isCart = true, refreshReduxCartExpiration) => {
  const [finished, setFinished] = useState(false);
  const [countDown, setCountDown] = useState(expirationDate);
  let countdownInterval = null;

  const reduxDispatch = useDispatch();

  useEffect(() => {
    refreshReduxCartExpiration && reduxDispatch({ type: 'UPDATE_EXPIRY', expiry_at: countDown });

    if (countDown <= 0) {
      setFinished(true);
      clearInterval(countdownInterval);
    }
  }, [countDown]);

  useEffect(() => {
    countdownInterval = setInterval(async () => {
      await setCountDown((count) => count - 1);
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [expirationDate]);

  return getReturnValues(countDown, finished);
};

export { useCountdown };
