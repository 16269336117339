/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Switch } from 'antd';
import Checkbox from '../../../../components/Checkbox';
import Button from '../../../../components/Button';
import clearString from '../../../../helpers/clearString';
import currencyFormat from '../../../../helpers/currencyFormat';
import account from '../../../../services/account';
import ecommerceServices from '../../../../services/ecommerce';
import CartContentCheckout
  from '../../../../components/Cart/CartContentCheckout';
import TermsModal from '../../../../components/termsModal';
import PaymentOptions from './PaymentOptions';
import EmptyCart from '../../../../components/Cart/EmptyCart';
import MUSIcon from '../../../../assets/images/MUS.svg';
import { history } from '../../../../helpers/history';

function SwitchSection({
  title, setOpen, open, show = true, SwitchContentComponent = null,
}) {
  return show && (
  <div className="switch">
    <button type="button" onClick={() => setOpen((state) => !state)} className="switch-container">
      <span className="switch-title">{title}</span>
      <Switch
        onChange={() => setOpen((state) => !state)}
        onClick={() => setOpen((state) => !state)}
        value={open}
      />
    </button>
    {open && SwitchContentComponent && <SwitchContentComponent />}
  </div>
  );
}

export function PaymentBill({ items }) {
  return items?.map((item) => {
    const payload = {
      label: item.label || 'Desconto',
      value: item.value,
      unit: item.unit || 'value',
    };

    return (
      <div
        key={payload.label + payload.value}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '0.5rem',
        }}
      >
        <span style={{
          fontSize: '14px',
          fontWeight: '400',
          marginRight: '0.25rem',
          textTransform: 'uppercase',
        }}
        >
          {payload.label}
        </span>
        <span
          style={{
            fontSize: '14px',
            fontWeight: '600',
          }}
        >
          {payload.unit === 'percent' && `${payload.value}%`}
          {payload.unit === 'value' && currencyFormat(payload.value)}
          {payload.unit === 'mus' && currencyFormat(payload.value)}
        </span>
      </div>
    );
  });
}

export default function PaymentStep({
  loading, setCheckoutData, setStep, withWallet, setWithWallet, setLoading, checkoutData, updateCardList,
}) {
  const [modalInfo, setModalInfo] = useState({
    type: '',
    opened: false,
  });
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(false);

  const [openedCardList, setOpenCardList] = useState(false);

  const [terms, setTerms] = useState([false, false]);
  const cartKey = localStorage.getItem('cartKey');
  const reduxDispatch = useDispatch();
  const methodsList = checkoutData.methods
    ? [
      ...checkoutData.methods?.map((method) => method),
    ]
    : [];

  async function onPay() {
    setLoadingPayment(true);
    const ls = JSON.parse(localStorage.getItem('clearsale_session'));

    const input = {
      zipcode: checkoutData.customer.address.data.zipcode,
      country: checkoutData.customer.address.data.country,
      state: checkoutData.customer.address.data.state,
      city: checkoutData.customer.address.data.city,
      district: checkoutData.customer.address.data.district,
      street: checkoutData.customer.address.data.address,
      number: checkoutData.customer.address.data.number,
      identity_type: 'CPF',
	    origin: 'web_site',
      identity: clearString(checkoutData.cart.customer.personal_information.cpf),
      amount: checkoutData.cart.total_price,
      session_id: ls.sessionId,
      payment_method: paymentMethod,

      ...(paymentMethod === 'credit_card' && {
        vault_id: checkoutData.cards?.find(
          (card) => card.id === checkoutData.sessionDefaultCard,
        )?.vault_id,
      }),
    };

    await ecommerceServices.doPayment(cartKey, input).then(async (response) => {
      setPaymentError(false);

      if (!response?.data || response.data?.message !== 'success') {
        setPaymentError(true);
        return;
      }

      localStorage.removeItem('cartKey');
      reduxDispatch({ type: 'UPDATE_EXPIRY', expiry_at: null });
      reduxDispatch({ type: 'CLEAR_CART' });
      reduxDispatch({ type: 'CLEAR_CART_CREDITS' });
      reduxDispatch({ type: 'UPDATE_CART', items: [] });
      history.push(`/checkout/${response?.data?.id}`);
    })
      .catch(() => setPaymentError(true))
      .finally(() => setLoadingPayment(false));
  }

  return (
    <>
      <TermsModal
        open={modalInfo.opened}
        handleModal={() => setModalInfo({
          type: '',
          opened: false,
        })}
        type={modalInfo.type}
      />

      {loading ? (
        <span className="loading">
          <CircularProgress />
        </span>
      ) : (
        <>
          {!checkoutData.cart ? (
            <EmptyCart />
          ) : (
            <section
              id="checkout-step-payment"
              className={`${loading ? 'loading' : ''}`}
              style={{ justifyContent: checkoutData.cart?.content?.length > 0 ? 'flex-start' : 'center' }}
            >
              <CartContentCheckout cart={checkoutData.cart} loading={loading} />
              <div className="payment">
                <SwitchSection
                  title="Usar saldo em carteira"
                  show={!loading && checkoutData?.cart?.partial_payment}
                  open={withWallet}
                  setOpen={setWithWallet}
                />
                <Divider className="bg-neutral-6" />
                <div className="infos">
                  {/* <div className="totals"> */}
                  {/*  <span>Total a pagar:</span> */}
                  {/*  <span className="total"> */}
                  {/*    {currencyFormat(checkoutData.cart.total_price)} */}
                  {/*  </span> */}
                  {/* </div> */}
                  <PaymentBill
                    items={[
                      {
                        label: 'Subtotal', value: checkoutData?.cart?.sub_total,
                      },
                      ...(checkoutData?.cart?.discounts || []),
                      {
                        label: 'Total a pagar', value: checkoutData?.cart?.total_price,
                      },
                    ]}
                  />
                </div>
                <Divider className="bg-neutral-6" />

                {!!checkoutData?.cart?.total_mus_reward && (
                <a target="_blank" href={process.env.REACT_APP_PLAY_URL || 'https://play.unindosonhos.com.br'} className="banner-mus" rel="noreferrer">
                  <p>
                    Nesta compra através do
                    {' '}
                    <strong>app você ganharia</strong>
                    {' '}
                    <img src={MUSIcon} width={32} height={32} alt="Moeda MUS" />
                    {' '}
                    <strong>
                      MUS! Baixe agora!
                    </strong>
                  </p>
                </a>
	              )}

                <PaymentOptions
                  setPaymentMethod={setPaymentMethod}
                  methods={methodsList}
                  checkoutData={checkoutData}
                  setCheckoutData={setCheckoutData}
                  updateCardList={updateCardList}
                  setOpenCardList={setOpenCardList}
                />

                <div className="terms-accept">
                  <Checkbox
                    label="Concordo com o"
                    withLink="Termo de Uso."
                    onLinkClick={() => setModalInfo({
					            type: 'use', opened: true,
				            })}
                    onClick={() => setTerms([!terms[0], terms[1]])}
                    checked={terms[0]}
                  />
                  <Checkbox
                    label="Concordo com o"
                    withLink="Termo de Consentimento para Tratamento de Dados."
                    onLinkClick={() => setModalInfo({
					            type: 'privacy', opened: true,
				            })}
                    onClick={() => setTerms([terms[0], !terms[1]])}
                    checked={terms[1]}
                  />
                </div>
                <p className={`${!paymentError ? 'hidden' : 'payment-error'}`}>Erro ao processar sua compra. Tente novamente mais tarde ou contate o nosso suporte.</p>
                <Button
                  type="submit"
                  action={onPay}
                  color="green"
                  width="100%"
                  label="Finalizar compra"
                  loading={loading || loadingPayment}
                  disabled={!terms[0] || !terms[1] || !paymentMethod || loadingPayment || (paymentMethod === 'credit_card' && openedCardList)}
                />
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
}
