const data = {
    data: `<h1>Termo de Consentimento para Tratamento de Dados</h1>
            <article>
                <p>
                    Este documento visa registrar a manifestação livre, informada e inequívoca pela qual o  Titular concorda com o tratamento de seus dados pessoais para finalidade específica,  em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais  (LGPD). 
                    Ao manifestar sua aceitação para com o presente termo, o Titular consente e concorda  que a Unindo Sonhos (@Unindosonhos) – PC de S LEITE – ME, inscrição no CNPJ sob  o nº 16.742.811/0001-06, com sede na Avenida Getúlio Vargas, 4856 – Sala 07 Galeria  Multivida – São Pedro – Boa Vista/Roraima – CEP: 69.306-700, doravante denominada  Controladora, tome decisões referentes ao tratamento de seus dados pessoais, dados 
                    referentes as empresas em que atuem os usuários ou dados necessários ao usufruto de  serviços ofertados, bem como realize o tratamento de tais dados, envolvendo operações  como as que se referem a coleta, produção, recepção, classificação, utilização, acesso,  reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento,  eliminação, avaliação ou controle da informação, modificação, comunicação,  transferência, difusão ou extração. 
                </p>
            </article>

            <article>
                <h1>Dados Pessoais </h1>
                <p>
                    A Controladora fica autorizada a tomar decisões referentes 
                    ao tratamento e a realizar o  tratamento dos seguintes dados 
                    pessoais do Titular: 
                </p>
                <ul>
                    <li>Nome completo;</li>
                    <li>Data de nascimento;</li>
                    <li>Número e imagem da Carteira de Identidade (RG);</li>
                    <li>Número e imagem do Cadastro de Pessoas Físicas (CPF);</li>
                    <li>Número e imagem da Carteira Nacional de Habilitação (CNH);</li>
                    <li>Número do Cadastro Nacional de Pessoas Jurídicas (CNPJ);</li>
                    <li>Fotografia 3x4;</li>
                    <li>Estado civil;</li>
                    <li>Filiação;</li>
                    <li>Nível de instrução ou escolaridade;</li>
                    <li>Endereço completo;</li>
                    <li>Números de telefone, WhatsApp, endereços de e-mail e identificações em redes  sociais;</li>
                    <li>Banco, agência e número de contas bancárias;</li>
                    <li>Bandeira, número, validade e código de cartões de crédito;</li>
                    <li>Nome de usuário e senha específicos para uso dos serviços do Controlador;</li>
                </ul>
                <p>
                    Além disso, a Controladora fica autorizada a tomar decisões referentes 
                    ao tratamento e  a realizar o tratamento dos dados inseridos pelo Titular, 
                    com a finalidade de divulgação  dos serviços, inclusive publicidade. 
                </p>
            </article>

            <article>
                <h1>Finalidades do Tratamento dos Dados </h1>
                <p>
                    O tratamento dos dados pessoais listados neste termo tem as seguintes finalidades:
                </p>
                <ul>
                    <li>Possibilitar que a Controladora identifique e entre em contato com o Titular para  fins de relacionamento comercial. </li>
                    <li>Possibilitar que a Controladora elabore contratos comerciais e emita cobranças  contra o Titular.</li>
                    <li>Possibilitar que a Controladora envie ou forneça ao Titular seus produtos e  serviços, de forma remunerada ou gratuita. </li>
                    <li>Possibilitar que a Controladora estruture, teste, promova e faça propaganda de  produtos e serviços, personalizados ou não ao perfil do Titular. </li>
                    <li>Possibilitar que a Controladora utilize tais dados em Pesquisas de Mercado; • Possibilitar que a Controladora utilize tais dados na inscrição, divulgação,  premiação dos interessados participantes de Eventos, Prêmios ou Concursos; • Possibilitar que a Controladora utilize tais dados na elaboração de relatórios e  emissão de produtos e serviços; </li>
                    <li>Possibilitar que a Controladora utilize tais dados para emissão de Notas Fiscais e  documentos financeiros correlatos; </li>
                    <li>Possibilitar que a Controladora utilize tais dados para facilitar a prestação de  serviços diversos além dos primariamente contratados, desde que o cliente  também demonstre interesse em contratar novos serviços; </li>
                    <li>Possibilitar que a Controladora utilize tais dados para manter banco de dados de  profissionais do mercado para facilitar o contato em futuros convites para eventos; </li>
                </ul>
            </article>

            <article>
                <h1>Compartilhamento de Dados</h1>
                <p>
                    A Controladora fica autorizada a compartilhar os dados pessoais do Titular com outros  agentes de tratamento de dados, caso seja necessário para as finalidades listadas neste  termo, observados os princípios e as garantias estabelecidas pela Lei nº 13.709/18.
                </p>
            </article>

            <article>
                <h1>Segurança dos Dados</h1>
                <p>
                    A Controladora responsabiliza-se pela manutenção de medidas de segurança, técnicas  e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de  situações acidentais ou ilícitas ou qualquer forma de tratamento inadequado ou ilícito. 
                    Em conformidade ao art. 48 da Lei nº 13.709/18, a Controladora comunicará ao Titular e  à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de  segurança que possa acarretar risco ou dano relevante ao Titular. 
                </p>
            </article>

            <article>
                <h1>Término do Tratamento dos Dados</h1>
                <p>
                    A Controladora poderá manter e tratar os dados pessoais do Titular durante todo o  período em que os mesmos forem pertinentes ao alcance das finalidades listadas neste  termo. Dados pessoais anonimizados, sem possibilidade de associação ao indivíduo,  poderão ser mantidos por período indefinido. 
                    O Titular poderá solicitar via e-mail ou correspondência ao Controlador, a qualquer  momento, que sejam eliminados os dados pessoais não anonimizados do Titular. O  Titular fica ciente de que poderá ser inviável ao Controlador continuar o fornecimento de  produtos ou serviços ao Titular a partir da eliminação dos dados pessoais. 
                </p>
            </article>

            <article>
                <h1>Direitos do Titular</h1>
                <p>
                    O Titular tem direito a obter da Controladora, em relação aos dados por ele tratados, a  qualquer momento e mediante requisição:  
                </p>
                <ul>
                    <li>confirmação da existência de tratamento;  </li>
                    <li>acesso aos dados;  </li>
                    <li>correção de dados incompletos, inexatos ou desatualizados; </li>
                    <li>anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou  tratados em desconformidade com o disposto na Lei nº 13.709/18;  • portabilidade dos dados a outro fornecedor de serviço ou produto, mediante  requisição expressa e observados os segredos comercial e industrial, de acordo  com a regulamentação do órgão controlador;  </li>
                    <li>portabilidade dos dados a outro fornecedor de serviço ou produto, mediante  requisição expressa, de acordo com a regulamentação da autoridade nacional,  observados os segredos comercial e industrial;  </li>
                    <li>eliminação dos dados pessoais tratados com o consentimento do titular, exceto  nas hipóteses previstas no art. 16 da Lei nº 13.709/18;  </li>
                    <li>informação das entidades públicas e privadas com as quais a Controladora realizou uso compartilhado de dados;  </li>
                    <li>informação sobre a possibilidade de não fornecer consentimento e sobre as  consequências da negativa;                                  </li>
                </ul>
            </article>

            <article>
                <h1>Direito de Revogação do Consentimento </h1>
                <p>
                    Este consentimento poderá ser revogado pelo Titular, a qualquer momento, mediante  solicitação via e-mail ou correspondência à Controladora.
                </p>
            </article>`
}
export default data;