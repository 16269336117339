/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Tooltip, Whisper } from 'rsuite';
import Button from '../../../components/Button';
import CartContentCheckout from '../../../components/Cart/CartContentCheckout';
import currencyFormat from '../../../helpers/currencyFormat';
import formatDate from '../../../helpers/formatDate';
import formatPaymentMethod from '../../../helpers/formatPaymentMethod';
import formatOrderStatus from '../../../helpers/formatOrderStatus';
import { history } from '../../../helpers/history';
import { PaymentBill } from '../steps/payment';
import logo from '../../../assets/images/logotipo-unindo-sonhos.png';

export default function DefaultResult({ order }) {
  const [codeCopy, setCodeCopy] = useState(false);

  const primaryMethod = order?.payments?.length === 1
    ? order?.payments[0]
    : order?.payments?.find((method) => method.payment_channel !== 'wallet');
  const walletMethod = order?.payments?.find((method) => method.payment_channel === 'wallet');

  return (
    <section id="checkout-step-box">
      <div id="checkout-success">
        <section>
          <h1 className="title">Pedido realizado com sucesso!</h1>
          <div className="info">
            <div className="info-text">
              <span className="info-title">Número do pedido</span>
              <span className="info-value">{order?.token}</span>
            </div>
          </div>
          <div className="info">
            <div className="info-text">
              <span className="info-title">Método de pagamento</span>
              <span className="info-value">{formatPaymentMethod(primaryMethod.payment_channel)}</span>
            </div>
            <div className="info-text">
              <span className="info-title">Status do pedido</span>
              {formatOrderStatus(order?.status)}
            </div>
          </div>
          {primaryMethod?.payment_channel === 'Boleto' && (
          <div className="barcode-box">
            <p className="info-title">
              <span>
                {`Expiração da compra: ${formatDate(primaryMethod?.expiration_date, true)}`}
              </span>
            </p>
            <p className="info-value" style={{ maxWidth: '80%', margin: '0 auto' }}>Se o pagamento não for efetuado dentro do prazo, sua compra irá ser cancelada pelo nossos sistema.</p>
            <a className="green" href={primaryMethod?.boleto_url} target="_blank" rel="noopener noreferrer">Link do boleto</a>
            <p className="info-value">
              Ou se preferir,
              {' '}
              <Whisper
                trigger="click"
                placement="top"
                controlId="control-id-top"
                open={codeCopy}
                speaker={
                  <Tooltip>Copiado.</Tooltip>
                }
              >
                <span
                  onClick={() => {
                    setCodeCopy(true);
                    navigator.clipboard.writeText(primaryMethod?.codigo_barras);
                    setTimeout(() => {
                      setCodeCopy(false);
                    }, 2000);
                  }}
                  style={{ textDecoration: 'underline', cursor: 'pointer', color: '#209869' }}
                >
                  copie o código de barras.
                </span>
              </Whisper>
            </p>
          </div>
          )}
          <hr className="bg-neutral-7 mt-4 mb-0 border-[2px]" />
          <h2 className="subtitle">Resumo do pedido</h2>
          <div className="order-items">
            <PaymentBill
              items={[
                {
                  label: 'Subtotal',
                  value: order?.sub_total,
                },
                ...(order?.partial_payment ? [{
                  label: 'Pago com saldo em carteira',
                  value: -(walletMethod?.amount || 0),
                }] : []),
                ...(
                  order?.discounts.length >= 0 ? order?.discounts?.map((discount) => ({
                    label: 'Uso de saldo MUS',
                    type: 'mus',
                    value: -(discount.amount),
                  })) : []
                ),
                {
                  label: 'Total pago',
                  value: order?.amount,
                },
              ]}
            />
          </div>
        </section>
        <section className="actions">
          <Button color="green" width="300px" label="Minhas compras" action={() => window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/dashboard/minhas-compras`} />
          <Button color="green invert" label="Ver bolões" width="300px" action={() => history.push('/boloes')} />
        </section>
      </div>
    </section>
  );
}
