/* eslint-disable no-unused-expressions */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import momentTz from 'moment-timezone';
import Routes from './routes';

import Login from './components/Login';
import Cart from './components/Cart';

import * as cartActions from './store/cart/actions';
import * as appActions from './store/app/actions';

import 'react-spring-bottom-sheet/dist/style.css';
import 'rsuite/dist/rsuite.min.css';
import './assets/sass/app.scss';
import './assets/sass/rsuite.css';
import './helpers/swipe-events';
import { WithAxios } from './services/api';
import cartService from './services/cart';
import { types } from './store/cart/types';

function App(props) {
  useEffect(() => {
    props.cartActions.updateCart();
  }, []);

  momentTz.tz.setDefault('America/Sao_Paulo');
  moment.locale('pt-br');
  window.moment = moment;
  window.momentTz = momentTz;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#209869',
          colorInfo: '#209869',
          colorPrimaryBorderHover: '#209869',
          colorPrimaryBorder: '#209869',
          colorPrimaryHover: '#209869',
          colorPrimaryActive: '#209869',
          colorPrimaryTextActive: '#209869',
        },
      }}
    >
      <WithAxios>
        <Login />
        <Cart cartData={props.cartData} />
        <Routes />
      </WithAxios>
    </ConfigProvider>
  );
}

const mapStateToProps = (state) => ({
  userData: state.userData,
  cartData: state.cartReducer,
});

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  cartActions: bindActionCreators(cartActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
