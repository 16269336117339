import React from 'react';

import json from '../../data/privacy-terms';
import './style.scss';

class TermsAndCondition extends React.Component {

    render() {
        return (
            <main id="terms-and-conditions">
                <div className="container">

                    <div className="row">
                        <span dangerouslySetInnerHTML={ { __html: json.data } }></span>
                    </div>

                </div>
            </main>
        );
    }
}
export default TermsAndCondition;